import React from "react"
import { Link, StaticQuery, graphql, Script } from "gatsby"
import ChatWidget from "./chatWidgetCharla"
import AnyChatWidget from "./AnyChat"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import facebookIcon from "./../../images/facebook.svg"
import youtubeIcon from "./../../images/youtube.svg"
import twitterIcon from "./../../images/twitter.svg"
import linkedinIcon from "./../../images/linkedin.svg"

const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 80px 10% 10% 10% 10% 10%;
  justify-content: space-evenly;
  /* margin-top: 100px; */
  padding: 40px 50px;
  background-color: rgba(47, 57, 78, 1);
  font-family: "Montserrat", sans-serif;

  @media (max-width: 1200px) {
    justify-content: space-between;
    grid-template-columns: 120px 15% 15% 15% 15% 15%;
  }
  @media (max-width: 1024px) {
    padding: 40px 30px;
  }
  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }
`

const FooterLogo = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px;
  @media (max-width: 1200px) {
    justify-content: flex-start;
    margin-left: 0;
  }
`
const FooterProduct = styled.div`
  display: flex;
  flex-direction: column;
`
const FooterMore = styled.div`
  display: flex;
  flex-direction: column;
`
const FooterLearn = styled.div`
  display: flex;
  flex-direction: column;
`

const FooterAddressFollow = styled.div`
  display: flex;
  flex-direction: column;
`
const FooterTitle = styled.h5`
  color: white;
  font-size: 18px;
  padding: 15px 0;
  font-weight: normal;
  margin: 0;
`
const FooterText = styled.div`
  font-size: 15px;
  margin: 6px 0;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`
const FooterRights = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 5px 15px 40px 15px;
  background-color: rgba(47, 57, 78, 1);
`

const FollowUs = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 21px);
  justify-content: start;
  grid-column-gap: 20px;
  padding: 15px 0;
`

const StyledA = styled.a`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
`
const FollowSvg = styled("img")`
  filter: brightness(100%);
  &:hover {
    filter: brightness(150%);
  }
`
const FollowSvgRest = styled("img")`
  filter: brightness(190%);
  &:hover {
    filter: brightness(250%);
  }
`

const Footer = ({ data }) => (
  <footer>
    <FooterContainer>
      <FooterLogo>
        <StyledLink to="/">
          <GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} />
        </StyledLink>
      </FooterLogo>
      <FooterProduct>
        <FooterTitle>Product</FooterTitle>
        <FooterText>
          <StyledLink to="/pricing">Pricing</StyledLink>
        </FooterText>
        <FooterText>
          <StyledLink to="/affiliate">Affiliate Program</StyledLink>
        </FooterText>
        <FooterText>
          <StyledLink to="/testimonials">Testimonials</StyledLink>
        </FooterText>
      </FooterProduct>

      <FooterMore>
        <FooterTitle>Integrations</FooterTitle>
        <FooterText>
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://groupboss.io/help/integrate-groupboss-with-mailchimp/"
          >
            Mailchimp
          </StyledA>
        </FooterText>
        <FooterText>
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://groupboss.io/help/integrate-groupboss-with-lemlist/"
          >
            lemlist
          </StyledA>
        </FooterText>
        <FooterText>
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://groupboss.io/help/integrate-groupboss-with-activecampaign/"
          >
            ActiveCampaign
          </StyledA>
        </FooterText>
        <FooterText>
          <StyledLink to="/integrations">All</StyledLink>
        </FooterText>
      </FooterMore>

      <FooterMore>
        <FooterTitle>More</FooterTitle>
        <FooterText>
          <StyledLink to="/faq">FAQ</StyledLink>
        </FooterText>
        <FooterText>
          <StyledLink to="/privacy-policy">Privacy policy</StyledLink>
        </FooterText>
        <FooterText>
          <StyledLink to="/terms-of-service">Terms of services</StyledLink>
        </FooterText>
        <FooterText>
          <StyledLink
            target="_blank"
            rel="noopener noreferrer"
            to="https://groupboss.io/sitemap/"
          >
            Sitemap
          </StyledLink>
        </FooterText>
        <FooterText>
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/groups/groupboss.io/"
          >
            Join Our Community
          </StyledA>
        </FooterText>
      </FooterMore>

      <FooterLearn>
        <FooterTitle>Learn</FooterTitle>
        <FooterText>
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://groupboss.io/blog/"
          >
            Read our blog
          </StyledA>
        </FooterText>
        <FooterText>
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://groupboss.io/help/"
          >
            Help & support
          </StyledA>
        </FooterText>
        <FooterText>
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/playlist?list=PLJfDMd2zbFu6WhQkAdbPvBiLJzkF7JklX"
          >
            Getting Started
          </StyledA>
        </FooterText>
        <FooterText>
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:support@groupboss.io"
          >
            Contact Us
          </StyledA>
        </FooterText>
      </FooterLearn>

      <FooterAddressFollow>
        <FooterTitle>Follow Us</FooterTitle>
        <FollowUs>
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/groupboss.io/"
          >
            <FollowSvg src={facebookIcon} alt="social-fb" width="100%" />
          </StyledA>

          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCkrO5Eod4EiIzC3P_fZScwg?sub_confirmation=1"
          >
            <FollowSvg src={youtubeIcon} alt="social-youtube" width="100%" />
          </StyledA>

          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/groupboss_io"
          >
            <FollowSvgRest
              src={twitterIcon}
              alt="social-twitter"
              width="100%"
            />
          </StyledA>

          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/groupboss/"
          >
            <FollowSvgRest
              src={linkedinIcon}
              alt="social-linkedin"
              width="100%"
            />
          </StyledA>
        </FollowUs>
      </FooterAddressFollow>
    </FooterContainer>
    <FooterRights>
      <div style={{ color: `rgba(255,255,255,0.6)` }}>
        © Copyright {new Date().getFullYear()} Groupboss. All rights reserved.
      </div>
      <div style={{ fontSize: `12px`, marginTop: `10px` }}>
        Groupboss is not affiliated by Facebook™ in any way. Facebook™ is a
        registered trademark Facebook Inc.
      </div>
    </FooterRights>
    {/* <script
      type="text/javascript"
      src="https://apiv2.popupsmart.com/api/Bundle/400112"
      async
    ></script> */}
    {/* Start of Tawk.to Script */}

    {/* <Script id="tawk-chat">
      {`var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        (function(){
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
          s1.async=true;
          s1.src='https://embed.tawk.to/655aeefad600b968d315016f/1hfljb1qa';
          s1.charset='UTF-8';
          s1.setAttribute('crossorigin','*');
          s0.parentNode.insertBefore(s1,s0);
        })();`}
    </Script>
    End of Tawk.to Script */}
    <Script id="claspo-add">
      {`!(function(t, e, c, n) {
          var s = e.createElement(c)
          ;(s.async = 1),
            (s.src = "https://scripts.claspo.io/scripts/" + n + ".js")
          var r = e.scripts[0]
          r.parentNode.insertBefore(s, r)
          var f = function() {
            f.c(arguments)
          }
          f.q = []
          f.c = function() {
            f.q.push(arguments)
          }
          t["claspo"] = t["claspo"] || f
        })(window, document, "script", "F6EA3D2E387D45A391C911BEA94CB427")`}
    </Script>
    <Script id="claspo-second-tag">{`claspo("init")`}</Script>
    <script
      key="anw2-sdk"
      dangerouslySetInnerHTML={{
        __html: `
          (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://api.anychat.one/widget2/load?id=1be54118-7597-376b-89cc-35ad7f95a481&r=' + encodeURIComponent(window.location);
            fjs.parentNode.insertBefore(js, fjs);
          })(document, 'script', 'anw2-sdk-GEHlG5d1azeJzDWtf5WkgQ');
        `,
      }}
    />
  </footer>
)

export default props => (
  <StaticQuery
    query={graphql`
      {
        logo: file(relativePath: { eq: "groupboss-logo-half-footer.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 60, height: 60, layout: FIXED)
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
